import React from "react"
import Layout from "../components/Layout"
import "../assets/css/material-dashboard.css"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col, Accordion } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`
const Cabecera = styled(Accordion)`
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
    background: black;
    color: white;
  }
`
// const isCurrentEventKey = currentEventKey === eventKey

const Preguntas = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Preguntas Frecuentes</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              En Yaku tenemos respuesta a todas las inquietudes sobre su próximo
              proyecto web
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/preguntas.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0" key="1">
                  <Accordion.Header bsPrefix="base">
                    ¿Los servicios de Yaku incluyen soporte técnico? 
                  </Accordion.Header>
                  <Accordion.Body>
                    Tanto sitios web como aplicaciones tienen soporte técnico
                    por 3 meses sin costo adicional. <br />
                    <br />
                    Esto incluye capacitación al personal de su organización
                    sobre todos los módulos de administración disponibles.
                    <br />
                    <br />
                    Sin embargo Yaku recomienda obtener el soporte técnico
                    especializado extendido al menos por un año con el fin de
                    contar con la asistencia profesional 24-7 necesaria en
                    proyectos importantes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" key="2">
                  <Accordion.Header bsPrefix="base">
                    ¿Los servicios incluyen asesoría estratégica para mi
                    proyecto?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yaku le proporciona soporte de planificación para su
                    proyecto. Mediante un análsis inicial brindamos ideas,
                    proyecciones, costos y diferentes escenarios con el fin de
                    que su proyecto tenga objetivos y metas claras con base a
                    información técnica. <br />
                    <br />
                    De igual manera trazamos un plan de objetivos por
                    entregables que le permite tener en todo momento un detalle
                    del avance de su proyecto.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" key="3">
                  <Accordion.Header bsPrefix="base">
                    ¿Si mi sitio web esta caído, como me pueden ayudar?
                  </Accordion.Header>
                  <Accordion.Body>
                    Si, efectivamente. Realizamos un análisis rápido de la
                    situación actual de su sitio con respecto a hosting y página
                    web. <br />
                    <br />
                    Se genera en menos 4 horas un informe con las primeras
                    recomendaciones a realizar sin costo adicional. <br />
                    <br />
                    Recuperar un sitio web puede ser complejo dependiendo los
                    casos pero generalmente se logra recuperar la presentación
                    del sitio en un periodo no mayor a 24-36 horas.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" key="4">
                  <Accordion.Header bsPrefix="base">
                    ¿Tengo garantía técnica para mi proyecto?
                  </Accordion.Header>
                  <Accordion.Body>
                    Si, efectivamente. Nuestra mayor garantía es nuestra alta
                    experiencia en el desarrallo web con más de 10 años de
                    servicio reconocido por su calidad por nuestros clientes.
                    <br />
                    <br />
                    Nuestro trabajo se enfoca a cumplir todas las
                    especificaciones técnicas establecidas al inicio del
                    proyecto. <br />
                    <br />
                    Utilizamos una metodología de hitos con entregables para
                    cada fase del proyecto con lo que podrá verificar el avance
                    de su proyecto en cualquier momento.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Preguntas
